<template>
	<div class="calendar">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>カレンダー</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">
									カレンダー
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div class="block section-padding-50">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-8 mb-4">
						<iframe
							src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23616161&amp;ctz=Asia%2FTokyo&amp;src=YXNzaXN0LWt5b3RvLmNvLmpwXzd1bGE2cXI3djlwc2kwb2pqbDB2MTRzbWVjQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&amp;color=%23E4C441&amp;showTitle=0&amp;showCalendars=1&amp;showTz=1"
							style="border-width: 0"
							width="100%"
							height="600"
							frameborder="0"
							scrolling="no"
						></iframe>
					</div>
					<div class="col-12 col-md-4">
						<h4>営業時間</h4>
						<p>10：00～18：00</p>
						<h4>定休日</h4>
						<p>月曜日、第2・4火曜日</p>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
export default {
	title: "カレンダー",
	components: {},
};
</script>

<style scoped lang="scss">
h4 {
	font-size: 1.2rem;
	position: relative;
	padding: 1.2rem;
	background-color: #f3f5f8;
	&:before {
		content: "";
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		left: -15px;
		top: 20px;
		border-right: 15px solid #f3f5f8;
		border-top: 15px solid transparent;
		border-bottom: 15px solid transparent;
	}
}
</style>
